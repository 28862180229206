import React from 'react';
import { Chart } from 'react-google-charts';
import { useInView } from 'react-intersection-observer';

const EvaluationChart = React.memo(function EvaluationChart() {
  const performanceData = [
    ['Model', 'Accuracy', { role: 'style' }],
    ['Medical Chat', 0.981, '#4CAF50'],
    ['OpenEvidence', 0.907, '#2196F3'],
    ['GPT4', 0.878, '#FFC107'],
    ['Anthropic Claude 2', 0.665, '#FF5722'],
    ['ChatGPT', 0.587, '#E91E63'],
  ];
  const [ref, inView] = useInView({
    triggerOnce: true, // Load content only once when it comes into view
  });
  // Convert accuracy values to percentage strings
  const percentageData = performanceData.map(([model, accuracy, style]) => [
    model,
    { v: accuracy, f: `${(accuracy * 100).toFixed(1)}%` }, // Displayed value in tooltip
    style,
  ]);

  const options = {
    title: 'United States medical licensing (USMLE) sample exam evaluation',
    chartArea: { width: '50%' },
    hAxis: {
      title: 'Accuracy',
      format: 'percent',
      minValue: 0,
    },
    vAxis: {
      format: 'percent',
    },
    legend: { position: 'none' },
    tooltip: { isHtml: true, trigger: 'both' },
    animation: {
      startup: true,
      easing: 'linear',
      duration: 500,
    },
  };

  return (
    <div ref={ref} className="chart-placeholder mt-4 md:mt-8 mx-auto">
      {inView && (
        <div className="absolute top-0 left-0 w-full">
          <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={percentageData}
            options={options}
          />
        </div>
      )}
    </div>
  );
});

export default EvaluationChart;
